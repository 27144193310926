import React from "react"

import Layout from "../components/layout"
import SEO from "../components/seo"

/** @jsx jsx */
import {
  jsx,
  Button,
  Heading,
  Text,
  Styled,
  Link as ThemeUILink,
} from "theme-ui"

const NotFoundPage = ({ location }) => (
  <Layout>
    <SEO
      location={location}
      title="404: Not found"
      description="404: Not found"
    />
    <Heading as="h1">404 - NOT FOUND</Heading>
    <Text>
      Unter diesem Pfad gibt es auf unserer Seite leiter nichts zu finden...
    </Text>
  </Layout>
)

export default NotFoundPage
